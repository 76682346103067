
.lock-button {
  display: flex;
  justify-content: center;
  align-items: center;  
  text-align: center;
    width: 200px;
    height: 50px;
    border-color: #3368FA;
    font-size: 20px;
    animation: pulse 1.5s infinite;
    box-shadow:1px 1px 20px 7px #3368FA;
    border-radius: 10px;
    outline: none;
    background-color: white;
    left: calc(100vw - 240px);
  cursor: pointer;
}
.lock-button:hover {
  animation: none;
}

.measure-logo {
  width: 25px;
  height: 25px;
  margin-left: 7px;
}

.go-back-button {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: whitesmoke;
  border: none;
  outline: none;
  cursor: pointer;
}

.go-back-button:hover {
  width: 47px;
  height: 47px;
}

.save-button {
    text-align: center;
    width: 200px;
    height: 50px;
    border-color: #3368FA;
    font-size: 20px;
    animation: pulse 1.5s infinite;
    box-shadow:1px 1px 20px 7px #3368FA;
    border-radius: 10px;
    outline: none;
    background-color: white;
    left: calc(100vw - 280px);
   cursor: pointer;
  }
  .save-button:hover {
    animation: none;
  }

  .map-tools-zoom, .draw-tools-back-undo {
    left: calc(100vw - 100px)
  }

  .map-tools-lock, .draw-tools-save-button {
    left: calc(100vw - 240px)
  }
  
  @keyframes pulse {
    0% {
      transform: scale(.9);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 50px rgba(#3368FA, 0);
      
    }
      100% {
      transform: scale(.9);
      box-shadow: 0 0 0 0 rgba(#3368FA, 0);
    }
  }